
@import '../node_modules/bootstrap/scss/_functions.scss';
@import '../node_modules/bootstrap/scss/_mixins.scss';
@import '../node_modules/bootstrap/scss/_variables.scss';



@media (max-width: 1000px)  {
  .pagination {
    margin-top: 1rem!important;
  }
}

.table-striped {

  .rs-table-body-row-wrapper {
    .rs-table-row{
      &:nth-of-type(odd)  {
        .rs-table-cell {
          background: rgba(0,0,0,.05);
        }
      }
      &:hover {
        .rs-table-cell {
          background: rgba(0,0,0,.075);
          cursor: pointer;
        }
      }
    }
  }
}

.double-rowed {
    tbody+tbody {
        border-top : none !important;
    }

    tbody {
      border-bottom: 1px solid #dee2e6;

      &:nth-of-type(odd) {
        tr {
          background-color: rgba(0,0,0,.05);
        }
      }
      &:hover {
        tr {
          background-color: rgba(0,0,0,.075);
        }
      }
      tr {

        th,
        td {
          border: none;
        }
      }

    }
}
.hide-screen {
  display: none;
}
@media print {

  .flex-table-outer {
      display: table;

      .flex-table {
        display: table-row;

        .flex-row {
          display: table-cell;

          .fa {
            display: none;
          }
        }
      }

  }

  .hide-screen {
    display: initial;
  }

    .table-row {
      .flex-row:nth-of-type(5) {
        vertical-align: middle;
        .checkboxes {
          &:before {
            flex-grow: 0;
            order: 2;
            content: ' ';
            border:solid 1px grey;
            display: block;
            width:30px;
            height:30px;
            border-radius: 2px;
            margin-right: 8px;
          }
          &:after {
            content: ' ';
            flex-grow: 1;
            order: 3;
            border:solid 1px grey;
            display: block;
            width:170px;
            height:100px;
            border-radius: 2px;
            margin-right: 8px;
          }
        }
      }

  }
}


//
// Basic Bootstrap table
//
.md-table {
  & > div > p {
    line-height: 1.5rem;
    margin-bottom: 0px;
  }
  table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.

    th,
    td {
      /*padding: $table-cell-padding;*/
      vertical-align: top;
      border-top: $table-border-width solid $table-border-color;
    }

    thead th {
      vertical-align: bottom;
      border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
      border-top: (2 * $table-border-width) solid $table-border-color;
    }
  }




  // Border versions
  //
  // Add or remove borders all around the table and between all the columns.

  table {
    border: $table-border-width solid $table-border-color;

    th,
    td {
      border: $table-border-width solid $table-border-color;
    }

    thead {
      th,
      td {
        border-bottom-width: 2 * $table-border-width;
      }
    }
  }



  // Zebra-striping
  //
  // Default zebra-stripe styles (alternating gray and transparent backgrounds)

  table {
    tbody tr:nth-of-type(#{$table-striped-order}) {
      background-color: $table-accent-bg;
    }
  }


  // Hover effect
  //
  // Placed here since it has to come after the potential zebra striping

  table {
    tbody tr {
      /*@include hover() {
        color: $table-hover-color;
        background-color: $table-hover-bg;
      } */
    }
  }


  // Table backgrounds
  //
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.

  /*@each $color, $value in $theme-colors {
    @include table-row-variant($color, theme-color-level($color, $table-bg-level), theme-color-level($color, $table-border-level));
  }

  @include table-row-variant(active, $table-active-bg);*/

}






.flex-table {
  display: flex;
  flex-flow: row wrap;

  transition: 0.5s;
  &.no-records {
    border-right: solid 1px $table-border;
    border-bottom: solid 1px $table-border;
  }
  &.table-row {
    margin-left: 0px;
    margin-right: 0px;
    &.hover-effect {
      &:hover {
        color: $iatg-hover;
      }
    }

  }
  &:first-of-type .flex-row {
    border-color: $table-border;
  }
  &.table-row:nth-child(even) .flex-row {
    /*background-color: rgba(0,0,0,.05);*/

  }

}

.flex-row {
  flex-basis: calc(100% / 4);
  padding: .5rem 0.75rem;
  border-bottom: solid 1px $table-border;
  [data-count]:after {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  &.module {
    flex:0 0 120px;
    @media print {
      padding-right: 0.25rem;
    }
  }
  &.section {
    flex:0 0 200px;
    @media print {
      padding-top: 1rem;
      padding-right: 0.25rem;
    }
  }
  &.associated {
    flex:0 0 auto;
  }

  &.title {
    flex-grow:1;
    @media print {
      padding-top: 1rem;
    }
  }

  &.list-actions {
    flex:0 0 400px;
  }

  &.actions {
    flex:0 0 120px;
  }

}

.header {
  .flex-row {
      border-bottom: solid 2px $table-border;
  }

}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
  .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px $table-border;

  }
}



.flex-cell {
  flex-basis: calc(100% / 3); //1px = border right
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px $table-border;
  //flex: 1 1 33.3%;
  &:last-child {
    // border-right: 0;
  }
}

/*@media all and (max-width: 767px) {
  .flex-row {
    width: calc(100% / 3); //1px = border right

   &.first {
     width: 100%;
   }
  }

  .column {
    width: 100%;
  }
}*/

@media all and (max-width: 900px) {

  .strong-single-col {
    font-weight: bold;
  }

  .flex-table {
    .flex-row {
      border-bottom: 0;
    }

    .flex-row:last-of-type {
      /*border-bottom: solid 1px $table-border;*/
    }
  }

  .header {
    display: none;
  }

  .flex-row {
    flex-basis: 100% !important;



    &:first-of-type {
      padding-top: 1rem;
    }
    &:last-of-type {
      padding-bottom: 1rem;
    }
     &.first {
       flex-basis: 100%;
       border-bottom: solid 1px $table-border;
     }

     .btn {
       max-width: 100px;
     }

  }

  .column {
    width: 100%;
    .flex-row {
      border-bottom: solid 1px $table-border;
    }
  }

  .flex-cell {
    flex-basis: 100%; //1px = border right

  }
}

.table-bordered,
.table-bordered thead r,
.table-bordered th,
.table-bordered td {
  border: none !important;
}


.table thead tr,
.table>:not(:first-child) {
  border-top: none;
}

.table tr {
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);;
}


.flex-row.actions {
  padding-right: 0;
}


@media all and (max-width: 900px) {
  .table-row {
    padding: 0 1rem;
    background: white;
    border: 1px solid #DDD;
    margin-bottom: 1rem;
  }
  .flex-row {
    padding: 0.5rem 0;
  }
}
