@font-face {
    font-family: Poppins-Regular;
    src: url('/styles/homepage/fonts/poppins/Poppins-Regular.ttf');
  }

  @font-face {
    font-family: Poppins-Bold;
    src: url('/styles/homepage/fonts/poppins/Poppins-Bold.ttf');

  }




  @font-face {
    font-family: 'Univers LT Std';
    src: url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-Cn.eot');
    src: url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-Cn.eot?#iefix') format('embedded-opentype'),
        url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-Cn.woff2') format('woff2'),
        url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-Cn.woff') format('woff'),
        url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-Cn.ttf') format('truetype'),
        url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-Cn.svg#UniversLTStd-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
}


  @font-face {
      font-family: 'Univers LT Std';
      src: url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-BoldCn.eot');
      src: url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-BoldCn.eot?#iefix') format('embedded-opentype'),
          url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-BoldCn.woff2') format('woff2'),
          url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-BoldCn.woff') format('woff'),
          url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-BoldCn.ttf') format('truetype'),
          url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-BoldCn.svg#UniversLTStd-BoldCn') format('svg');
      font-weight: bold;
      font-style: normal;
  }


  @font-face {
    font-family: 'Univers LT Std';
    src: url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-CnObl.eot');
    src: url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-CnObl.eot?#iefix') format('embedded-opentype'),
        url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-CnObl.woff2') format('woff2'),
        url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-CnObl.woff') format('woff'),
        url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-CnObl.ttf') format('truetype'),
        url('/styles/homepage/fonts/UniversLTStd/UniversLTStd-CnObl.svg#UniversLTStd-CnObl') format('svg');
    font-weight: normal;
    font-style: italic;
}
body {
  font-size: 20px;
  
}

body, h2, h3, h1, h4, label, .input , button{
  font-family: Univers LT Std,serif !important;
}

h1 {
  width: fit-content;
  border-bottom: solid 2px $am-accent-color;
  padding-bottom: 0.5rem;
  padding-right: 0.75rem;
  font-size: 1.5rem !important;
}

h2{

    font-size: 20px;
    margin: auto;
    font-weight: bold;
    padding: 5px 0 0 0;
    color: #333333;
    position: relative;
    left: 0px;
    font-size: 1.5rem !important;
}
h3{

    font-size: 1.25rem !important;
}
.badge{
    margin:5px 2px;
    font-size: var(--bs-body-font-size);
}
.fw-normal {
  .badge {
    font-weight:normal!important;
  }
}
pre{
    font-family: Univers LT Std,serif !important;;

    font-size: 18px !important;

    > p,
    li {
      margin-top: 15px !important;
      margin-bottom: 15px !important;

      &:first-child {
        margin-top: 0px !important;
      }
      &:last-child {
        margin-bottom: 0px !important;
      }

      p {
        margin-top: 0px !important;
        margin-bottom:0px !important;
      }
    }
}
.bg-grey
{
  background-color: #efefef;
}


.navInnerWrapper{ 
  .nav-link {
    padding: 1rem 1rem .75rem 1rem;
    line-height: 37px;
    display: inline;
   
  }
}
#mainNav{
  background-color: #292F36;
  font-size: 1.25rem !important;
  transition: height 0.3s;
  color:#fff;
  padding: 0;
  #logo img{
      width: 165px;
      top: -6px;
      position: relative;
  }
  .nav-link {
    @media (max-width: 1200px) {
      padding: 1rem 0.4rem;
    }
     display: block;
    border-bottom: solid 6px transparent;

    @media (max-width: 992px) {
      border-bottom: 0 !important;

      &.btn {
        margin: 1rem 0;
        padding: 0.4rem 1rem;
      }
    }
  }
  &.navShrink{
      background:#212529;
      transition: height 0.3s;
  }

  .nav-item.dropdown {
    @media (max-width: 992px) {
      padding: 0 1rem;
      padding: 0rem 0.4rem;
    }
  }

  .dropdown-menu {
    background-color: #292F36;
    border: 0;
    border-radius: 0;
    .dropdown-item {
      text-transform: capitalize;
      color: #FFF !important;
      font-size: 1.25rem;

      &:focus, &:hover, &:active, &.active {
        background-color: #373d44;
      }
    }
  }



  .navbar-collapse {
    @media (max-width: 992px) {
      background-color: black;
      padding: 15px;
      /*margin-top: 2em;*/
      position: absolute;
      width: 100%;
      z-index: 300;
    }
  }



  .navbar-nav {
    @media (max-width: 1024px)  {
      margin-bottom: 0.6em;
    }

    .nav-link {
      transition: none;

      &:hover,
      &.active {
        border-bottom-color: #b2b2b1;
      }
    }

  }
  .fa-stack {
    @media (max-width: 1024px)  {
      width:1em;
      margin: .8em 0 !important;
    }
  }

}

.headerTop{
  background: #fff;
  overflow: hidden;
  padding: 1rem;
}

.headerTop .amat-logo
{
  width: 240px;
  height: 60px;
}

.headerTop .unsaferguard-logo {
  margin-left: 1rem;
  padding: 0.6rem 0.5rem 0;
}

.headerTopLink{
  float:right;
  margin-top: 21px;
}

.headerTopLogoWrapper{
  width: 50%;
}

.headerTopLink a{
  padding: 1rem;
  text-transform: uppercase;
  color: #262626;
  font-size: 90%;
  font-weight: 700;
  letter-spacing: .05rem;
  text-decoration: none;
  gap: 2rem;
}

.navInnerWrapper{
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  
}



.navbar-brand span {
    color: #3297ff;
}



.controls {
  .badge{
     min-width: 3.7rem;
     padding-top: .75em;
     padding-bottom: .75em;
     margin-left: 0px;
     margin-right: 0px;
   }
   h1 {
     .badge{

        padding-top: .25em;
        padding-bottom: .25em;
        font-weight: normal;

      }
   }

}


.filter {
  .badge,
  .switch{
    width:100%;
    min-width: auto !important;
  }
}

#mainNav {
    a.nav-link:not(.btn){
      color:#fff !important;
  }
}


@keyframes nav {
    0% {
        width: 200px;
    }
    100% {
        width: 150px;
    }
}

.modal-content{
    font-size: var(--bs-body-font-size);
    padding: 10px;
    h3 {
      font-weight: 700;
    }
    h4{
        font-size: 18px;
        font-weight: bold;
    }
    a {
      line-break: anywhere;
      text-decoration: none;
    }

    .close-align-self-start {
      .close {
        align-self: start;
      }
    }
}

.controls {
    padding: 110px 0 5px 0;

    @media print {
      padding-top:40px;
    }

    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    width: 100%;


    label{
        color: #fff;
        margin: .5rem 0 .5rem 0;
    }

    @media (max-width: 768px) {
      .switch,
      .badge {
        width:100%;min-width: 61px;
        min-width: 65px;
      }
    }

}
@include media-breakpoint-down(md) {
  .no-padding-start-md {
    > a {
      padding-left: 0px !important;
    }
  }
}

.fill-bg {
  .controls {
    margin-bottom: 0px;
  }
  main {
    .container {
      padding-top: 20px;
    }
  }
}
.row-seven-padding
{
  display: flex;
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;

  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12,
  .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6,
   .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 7px;
    padding-left: 7px;
  }
}

.filter {
    padding: 0px;
    margin: 10px 0;
    border-radius: 5px;
    min-width: 200px;
    flex-grow: 1;
    flex-basis: 0;
    .link{
        color:#fff;
    }
}
.flex-basis-90 {
    flex-basis: 90px;
}
div[class*="-control"] > div:first-child {
  padding-left:2px;
  padding-right:2px;
}

#text_search {
  padding-left: 4px;
  padding-right: 4px;
}

div[class*="-multiValue"] {
  float:left;
}

div[class*="-control"] > div:first-child >  div:last-child {
  float:left;
}



.btn-dark{
  background-color: #6d7e8a !important;
  border: none;
}
.switch-on{
  color:rgba(255, 255, 255, 0.6) !important;
}

.switch-off{
  color:rgba(0, 0, 0, 0.4) !important;
  text-shadow: 0px 1px 1px #888888;
}

.App {
  background-color: #efefef;
}
label ,
a {
  color:#000 !important;

}

a {
  color:#000 !important;

  &:hover {
    //color: #c10a25 !important;
  }
}
.pe-6 {
  padding-right: 2.5rem !important;

}
main {

  min-height: calc(100vh - 165px);
  @media (min-width: 576px) {
    min-height: calc(100vh - 204px);
  }
}
.filter {
  .toast{
      position: fixed;
      z-index: 1050;
      top: 60px;
      right: 10px;
      color: #fff;
  }
}
[data-count]:after{
  position: absolute;
  top: 0;
  content: attr(data-count);
  font-size: 1rem;
  padding: 0.75rem;
  border-radius: 50%;
  line-height: 0.5em;
  color: white;
  background-color: #007bff;
  text-align: center;
  min-width: 1rem;
  font-weight: bold;
  margin-top: 0.25rem;
}
.nav-link[data-count]:after {
    top: 0rem;
}
.fa-stack[data-count]:after{
  left:2.5em;
}
.after-ms-1[data-count]:after{
  margin-left:.25em;
}
.d-inline-block[data-count]:after {
    right: -1rem;
}
.danger[data-count]:after{
  background:rgba(255,0,0,.85);
}

.item_count {
    color: #ffffff;
    padding: 5px;
    min-width: 220px;
    width: 50%;
    background-color: #3297FF;
    margin: auto;
    border-radius: 30px;
    text-align: center;
    font-size: 24px;
    margin: 10px auto 20px auto;
    -webkit-box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
    -moz-box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
    box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
}

.user-name{

    & i{
      padding: 5px;
      @media (max-width: 1024px)  {
        padding-left:0px;
      }
    }
}
.modal-spinner {
  height: 100vh;
  width: 100vw;
  position:fixed;
  top:0px;
  left:0px;
  background-color: rgba(0,0,0,.5);
  z-index: 1600;
}

.cookie-consent {
  bottom: 0;
  position: fixed !important;
  right: 0;
  border-radius: 4px;
  z-index: 1500;
  h2 {
    color: #fff;
    margin: 0px;
    font-size: 2em;
  }
  .fa{
    cursor: pointer;
    font-size: 24px;
  }
  p{
    font-size: 14px;
  }
  .btn {
    font-weight: 400;
  }
}

.toast {
  &.notications {
    background-color: #fff;
    border-radius: .45em;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
    overflow-y: auto;
    max-height: 80vh;

    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: #fff transparent;
        border-width: 0 10px 10px;
        top: -9px;
        right: 1em;

    }
    ul {
      list-style: none;
    }
    .toast-header {
      display: none;
    }
    .toast-body {
      padding: 0px;

      ul {
        padding: 0px;
        margin-bottom: 0px;
        li {
          padding: 1em;
          border-bottom:solid 1px #ddd;
          background-color:#fff;
          transition: background-color 300ms linear, color 300ms linear;
          &:first-child {
            border-radius: .45em .45em 0 0;
          }
          &:last-child {
            border-bottom: none;
            border-radius: 0 0 .45em .45em;
          }
          &:not(:last-of-type) {
            &:not(.no-hover) {
              &:hover {
                background-color: #0275d8;
                color: white;
                p,a {
                  color:white;
                }
                .heading {
                  color: white;
                }
              }
            }
          }
          p {
            margin-bottom: .3em;
            line-height: 1.5;
            cursor: default;
            &:last-child {
              margin-bottom: 0em;
            }
          }
        }
      }
      p,a {
        color:#929292;
      }
      .heading {
        color:black;
        font-size: 24px;
      }
    }
  }

}
.float-end-desktop {
    float:right;
}

  @media (max-width: $bp-tablet) {
    .container {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .filter {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .float-end-desktop {
        float:none;
    }

  }
  .abscenter {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: red;
  }

  .custom-dropdown {
    button {

      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      display: flex;
      &.btn-primary {
        background-color: transparent !important;
        border-color: hsl(0,0%,80%);

        &:after {
          color: hsl(0,0%,80%);
        }
        div {
            color: #333333;
        }
      }


    }
    .dropdown-item {
      padding-left: 0.75em;
      padding-right: 0.75em;
      &:before {
        font-family: FontAwesome;
        display: inline-block;
        font: FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding-right: 0.5em;
        content: "\f067";
      }
      &.active {
        &:before {
          padding-right: 0.4em;
          content: "\f00c";
        }
      }
    }
  }
  
  .modal-header {
    .close {
      align-self: end;
    }
  }
  .modal-A4 {

    &.carousel-modal {
      .modal-header {
        border-bottom:none;
        padding-bottom:0px;
      }
      .modal-body {
        border-top:none;


      }
      .carousel-item-inner {
        margin-top:1rem;
        border-top: 1px solid #dee2e6;
      }
    }

    @media (min-width: 576px) {
      .modal-dialog {
        width:calc(25.5cm + 3rem);
        max-width: none;
        .modal-content {
          padding: 1.5rem;
          .modal-header {
            padding-left: 0px;
            padding-right:0px;
          }
          .modal-body {
            overflow-x: auto;
            padding:0px;
          }
          .modal-footer {
            padding-left: 0px;
            padding-right:0px;
          }
        }
      }

      &.carousel-modal {
          .modal-dialog {
            width: calc(25.5cm + 6.2rem);
            .modal-content {
              .modal-body {
                overflow-x: hidden;
              }
            }
          }
      }

    }
  }

  /*!
   * Start Bootstrap - Agency v5.2.2 (https://startbootstrap.com/template-overviews/agency)
   * Copyright 2013-2019 Start Bootstrap
   * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-agency/blob/master/LICENSE)
   */


  a {
    color: #389cff;
  }

  a:hover {
    color: #1a72c9;
  }

  header.masthead {
    background-image: url('../assets/img/home-bg.jpg');
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;   
    height: calc(100vh - 165px);
    @media (min-width: 576px) {
      height: calc(100vh - 204px);
    }
  }

  header.masthead .intro-text .intro-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    min-height: 5rem;
  }
  header.masthead .intro-text p {
    margin-bottom: 15px;
  }

header.masthead .intro-text .intro-heading {
  font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      header.masthead .intro-text .intro-heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 15px;
      }
      header.masthead .intro-text p{
        margin-bottom: 15px;
      }
    }
}

header.masthead .intro-text .intro-description {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.2rem;
}
@media (max-width: 576px) {
  header.masthead .intro-text .intro-description{
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.6rem;
  }
}

  @media (min-width: 768px) {

    header.masthead .intro-text .btn{
      display:inline-block;
          min-width: 260px;
          padding-left: 22px !important;
    }




    header.masthead .intro-text .intro-heading {
      font-size: 75px;
      font-weight: 700;
      line-height: 75px;
      margin-bottom: 15px;
    }
    header.masthead .intro-text p{
      margin-bottom: 15px;
    }
  }
  #mainNav .navbar-toggler {
    margin-right: 0.75rem;
    float: right;
    font-size: 1rem;
    right: 0;
    padding: 13px;
    text-transform: uppercase;
    color: white;
    border: 0;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  footer {
    &::before {
      content: ' ';
      width: 100%;
      height: 1px;
      background-color: #ccc;
      display: block;
      position: absolute;
    }
    >* {
      width: 95%;
      margin: auto;
    }
  }
