.btn {
  border-radius: 0px !important;
}
.btn-large {
  font-size: 1.25rem !important;
}

.btn-arrow {
  border: solid 1px $am-accent-color !important;
  padding: 0px 0px 0px 1rem !important;
  height: 2.3rem !important;
  background-color: #fff !important;
  align-self: flex-start;
  display: flex ;
  justify-content: flex-end;


  span {
    height: 100%;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .link-arrow {
      margin-left: 1rem;
      background-color: $am-accent-color;
      height: 100%;
      padding: 0 0.7rem 0 1rem;
      font-size: 1.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .fa {
        transform: rotate(45deg);
        color: white;
        &.fa-i-cursor {
          transform: rotate(0deg);
        }
      }
      .fa-share, .fa-trash, .fa-search {
        transform: initial;
        position: relative;
        padding: 0 0.1rem;
        left: -0.15rem;
      }

  }

  &:hover {
    border-color: $iatg-hover !important;
    .link-arrow {
      background-color: $iatg-hover;
    }
  }
}

.btn-rename {
  border: solid 1px #198754 !important;
}

.link-rename{
  background-color: #198754 !important;
}

.btn-delete {
  border: solid 1px #dc3545 !important;
}

.link-delete{
  background-color: #dc3545 !important;
}

.btn-guidelines {
  min-width: 16rem;

  .guidelines-counter {
    &:after {
      color: #000;
      position: relative;
      padding: 0rem;
      background: none !important;
      font-weight: normal;
    }
  }
}
