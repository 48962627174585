

$am-base-color: #fbecec;
$am-dark-color: #4D4D4D;
$am-accent-color: #b2b2b1;
$am-border-color: #c42345;
$am-cookie-consent-color: #130f40;


body {

    background-color: #F2F2F2;

    .intro-text {
      color: $am-dark-color;
    }
    background-color:white;

   .btn-primary {
      color: #ffffff !important;
   }

   .dropdown-item.active{
    background-color: #c10a25 !important;
    }
    [data-count]:after,
    .badge-info {
      background-color: $am-accent-color;
    }
    .btn-outline-primary {
      color: $am-accent-color !important;
      border-color: $am-accent-color !important;

      &:hover {
        background: none;
        color: $iatg-hover  !important;
        border-color: $iatg-hover !important;
      }
    }
    & .item_count, .btn-primary {
        background-color: $am-accent-color;

    }
    & .btn-primary:hover,
    & .btn-primary:active{
        background-color: $iatg-hover !important ;
    }
    & .btn-primary{
        border-color: $am-accent-color;
    }
    .page-item.active .page-link {
        border-color: $am-accent-color;
        background-color: $am-accent-color;
    }
    &.fill-bg {
      main {
        > div {
          > .container {
            background-color: white;
          }
        }
      }
    }
    .toast {
      .toast-header,
      .toast-body {
        background-color: #fff;
      
        .text-muted  {
          color:white;
        }
        .close {
          color:black;
        }
      }
    }

    .cookie-consent{
      background-color:$am-cookie-consent-color;
    }

    .bg-orange {
      background-color: #b2b2b1;
    }
    .loader {
      span {
        &:nth-child(1){
          background-color: #FFFFFF;
        }

        &:nth-child(2){
          background-color: $am-accent-color;
        }

        &:nth-child(3){
          background-color: #FFFFFF;
        }
      }
    }
}
