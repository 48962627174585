
html[dir="rtl"] {

  @each $spacer, $value in $spacers {

    .ms-#{$spacer} {
      margin-right: $value  !important;
      margin-left: unset !important;
    }
    .me-#{$spacer} {
      margin-left: $value  !important;
      margin-right: unset !important;
    }

    .ps-#{$spacer} {
      padding-right: $value  !important;
      padding-left: unset !important;
      &.nav-link {
        padding-left: $navbar-nav-link-padding-x  !important;
      }
     }

    .pe-#{$spacer} {
      padding-left: $value  !important;
      padding-right: unset !important;
      /*&.nav-link {
        padding-right: $navbar-nav-link-padding-x  !important;
      }*/
    }

  }
  pre {
    direction: rtl;
  }
  .ms-auto {
    margin-right: auto!important;
    margin-left: unset!important;
  }
  .me-auto {
    margin-left: auto!important;
    margin-right: unset!important;
  }

  .float-end {
    float: left !important;
  }

  .float-start {
    float: right !important;
  }

  .input-group-text {
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }

  $validation-messages: "";
  @each $state in map-keys($form-validation-states) {
    $validation-messages: $validation-messages + ":not(." + unquote($state) + "-tooltip)" + ":not(." + unquote($state) + "-feedback)";
  }

  .input-group {
    > :not(:first-child):not(.dropdown-menu)#{$validation-messages} {
     margin-left: 0;
     margin-right: -$input-border-width;
     border-radius: 0.25rem 0  0  0.25rem !important;
   }
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
    background-position: left calc(0.375em + 0.1875rem) center;
  }

  .btn-group>.btn-group:not(:last-child)>.btn,
  .btn-group>.btn:first-child:not(.dropdown-toggle) {
    border-top-right-radius: 0.25rem ;
    border-bottom-right-radius: 0.25rem ;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .dropdown-toggle:after {
    margin-right: .35em;
  }

  .btn-group>.btn-group:not(:first-child)>.btn,
  .btn-group>.btn:nth-child(n+3),
  .btn-group>:not(.btn-check)+.btn {
    border-top-right-radius: 0 ;
    border-bottom-right-radius: 0 ;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .float-end-desktop {
    float: left;
  }

  .masthead {
    .intro-text {
      .btn-arrow {
          padding-left: 0px !important;
      }
    }
  }

.btn-arrow {
    padding-left: 0px !important;
    padding-right: 0 !important;
    span {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &.btn-sml {

    }
   .link-arrow {
     margin-left: 0px;
     margin-right: 0;
   }
}
  #mainNav .navbar-toggler {
    margin-left: 0.75rem;
    float: left;
  }

  @include media-breakpoint-down(md) {
    .no-padding-start-md {
      > a {
        padding-right: 0px !important;
      }
    }
  }

}
