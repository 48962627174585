.grecaptcha-badge {
    visibility: hidden;
}

.nav-link {
  text-transform: capitalize;

  &.btn {
    &:hover {
      color:white;
    }
  }
}

.nav-brand {
  width: 240px;
  height: 60px;
  margin-left: 1rem;
  background: url("./../assets/img/AMAT-logo-white.png") no-repeat;
  background-size: 218px 52px;
  background-position-y: 0.5rem;

  @media (max-width: 992px) {
    margin-left: 0;
    display:inline-block !important;
    background-size: 160px;
    height: 45px;
    background-position-x: 0.8rem;
    background-position-y: 0.4rem;
  }

  &.mobile {
    @media (min-width: 993px)  {
      display:none !important;
    }
  }
}

.navbar-nav {
  .nav-brand {
    @media (max-width: 992px)  {
      display:none !important;
    }
  } 
}

.input-group-text {
    min-width: 2.6rem;
    justify-content: center;
}

pre {
  white-space: break-spaces;
}
.white {
  color: #fff;
}
.blue {
  color: #007bff;
}
.link-color {
  color: #0056b3;
}
.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.badge {
  padding: .5em .8em;
  border-radius: 0px !important;
}

.form-control {
   border-radius: 0px !important;

   &:focus {
     border: none !important;
     box-shadow: none !important;
   }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: 0 !important;
    outline: none !important;
}
.btn-success {
    color: #fff !important;
}
.btn-create {
  background-color: #373d44 !important;
  color: white !important;
}
.btn-create:hover {
  background-color: #292F36 !important;
  color: white !important;
}
.btn-create:focus {
  box-shadow: none !important;
}
.page-link {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    height: 100%;
    line-height: 36px;
}

.popover {

  &.wide {
    min-width:40%;
    width:auto !important;
    max-width:60% !important;
  }

}

.badge{
  margin:3px 0;
}

.loading-spinner {
  height: 100px;
}
.big-top-pad {
  padding-top: 120px

}
.flex-desktop {
  display: flex;

  @media (max-width: 1000px)  {
    display: inline-block;
  }
}

.hidden-tablet-landscape {
  display: initial !important;
  @media (max-width: 1024px)  {
    display:none !important;
  }
}

.visible-tablet-landscape {
  display: none !important;
  @media (max-width: 1024px)  {
    display:initial !important;
  }
}

.hidden-900 {
  display: initial !important;
  @media (max-width: 900px)  {
    display:none !important;
  }
}

.visible-900 {
  display: none !important;
  @media (max-width: 900px)  {
    display:initial !important;
  }
}

.visible-desktop {
  display: initial;

  @media (max-width:  1000px)  {
    display:none;
  }
}

.hidden-desktop {
  display: none;

  @media (max-width: 1000px)  {
    display:initial;
  }
}
.pointer {
  cursor: pointer !important;
}
.float-badge {
  position: relative;
  padding-right: 24px !important;
  .badge {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.bold-h3 {
  h3 {
    font-weight: bold;
  }
}
